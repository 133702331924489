<template>
  <div>
    <div>
      <v-dialog v-model="zoomImage" width="unset">
        <v-card style="padding: 20px">
          <v-card-title style="display: flex; justify-content: flex-end">
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </v-card-title>
          <v-card-content style="justify-content: center; display: flex;">
            <img v-if="link.image" class="zoom-image" :src="link.image" />
          </v-card-content>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="link.image" class="image-box" @click="openDialog">
      <img class="image-size" :src="link.image" />
    </div>
    <div style="margin-bottom: 20px;">
      <v-simple-table>
        <tbody>
          <tr>
            <td>
              <div class="table-title">Amount</div>
              <div class="table-content">৳{{ link.amount }}</div>
            </td>
            <td>
              <div class="table-title">Link Validity</div>
              <div class="table-content">{{ link.expiryDate }}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="table-title">Stock Count</div>
              <div class="table-content">{{ link.stockCount || "N/A" }}</div>
            </td>
            <td>
              <div class="table-title">Product Name</div>
              <div class="table-content">{{ link.productName || "N/A" }}</div>
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </v-simple-table>
    </div>
    <div style="display: flex; justify-content: center; margin-bottom: 10px; ">
      <v-btn
        depressed
        color="#FAEDF3"
        style="color: #e2136e;"
        @click="copyPaymentLink"
      >
        <v-icon>
          mdi-content-copy
        </v-icon>
        Copy Link
      </v-btn>
    </div>
    <div
      style="display: flex; flex-direction: column; align-items: center; margin-bottom: 20px;"
    >
      Share via
      <div>
        <ShareNetwork
          network="whatsapp"
          :url="paymentLink"
          :title="shareTitle"
          :description="shareDescription"
          :quote="shareQuote"
          :hashtags="shareHashtags"
        >
          <img
            style="width: 40px; height: 40px; margin: 10px;"
            src="@/assets/image/logo-whatsapp.svg"
          />
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          :url="paymentLink"
          :title="shareTitle"
          :description="shareDescription"
          :quote="shareQuote"
          :hashtags="shareHashtags"
        >
          <img
            style="width: 40px; height: 40px; margin: 10px;"
            src="@/assets/image/logo-facebook.svg"
          />
        </ShareNetwork>
        <ShareNetwork
          network="messenger"
          :url="paymentLink"
          :quote="shareQuote"
          :hashtags="shareHashtags"
        >
          <img
            style="width: 40px; height: 40px; margin: 10px;"
            src="@/assets/image/logo-messenger.svg"
          />
        </ShareNetwork>
      </div>
    </div>
    <v-btn
      outlined
      color="primary"
      width="100%"
      @click="$router.push('/payment-links')"
      >Close</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "PaymentLinkDetails",
  props: ["link"],
  data() {
    return {
      zoomImage: false,
    };
  },
  computed: {
    shareTitle() {
      return process.env.VUE_APP_SHARE_TITLE;
    },
    shareDescription() {
      return process.env.VUE_APP_SHARE_DESCRIPTION;
    },
    shareQuote() {
      return process.env.VUE_APP_SHARE_QUOTE;
    },
    shareHashtags() {
      return process.env.VUE_APP_SHARE_HASHTAGS;
    },
    paymentLink() {
      return process.env.VUE_APP_CUSTOMER_PORTAL_URL + this.link.urlPath;
    },
  },
  methods: {
    closeDialog() {
      this.zoomImage = false;
    },
    openDialog() {
      this.zoomImage = true;
    },
    copyPaymentLink() {
      const copied = navigator.clipboard.writeText(this.paymentLink);
      if (copied) {
        this.$gtag.event("Payment link copied", {
          event_category:
            this.$route.name === "dashboard"
              ? "Dashboard Page"
              : "Payment Links Page",
        });
        this.$feedback.showShortToast("Link Copied!");
      }
    },
  },
};
</script>

<style scoped lang="scss">
$mobileW: 500px;
$mobileSm: 395px;
$mobileM: 565px;

.image-box {
  display: flex;
  width: 525px;
  height: 227px;
  padding: 2px 2px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #f3f3f3;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;

  @media (max-width: $mobileM) {
    width: 100%;
  }
}

.image-size {
  max-width: 522px;
  max-height: 223px;

  @media (max-width: $mobileM) {
    max-width: 100%;
    max-height: 150px;
  }
}

.zoom-image {
  max-height: 725px;
  max-width: 725px;
  object-fit: contain;

  @media (max-width: $mobileM) {
    max-height: 80%;
    max-width: 80%;
  }
}

.table-title {
  color: var(--Text-Secondary, #777);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 114.286% */

  @media (max-width: $mobileM) {
    font-size: 12px;
  }
}

.table-content {
  color: var(--grey-dark-grey-1, #464646);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  padding: 0;
  /* 100% */

  @media (max-width: $mobileM) {
    font-size: 14px;
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 !important;
}
</style>
