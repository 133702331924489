<template>
  <v-card
    elevation="0"
    width="606"
    style="margin: 100px auto auto auto; background-color: #fff; padding: 40px"
    class="card-container"
  >
    <v-icon size="36" color="#01846C">mdi-check-circle</v-icon>
    <div class="title-text">
      Payment Link Created <span style="color: #01846C;">Successfully</span>
    </div>
    <PaymentLinkDetails :link="link"></PaymentLinkDetails>
  </v-card>
</template>

<script>
import PaymentLinkDetails from "./components/PaymentLinkDetails.vue";

export default {
  name: "CreatePaymentLinkSuccess",
  components: { PaymentLinkDetails },
  data() {
    return {
      link: null,
    };
  },
  mounted() {
    let id = this.$route.params.id;
    this.getPaymentLink(id);
  },
  computed: {
    shareTitle() {
      return process.env.VUE_APP_SHARE_TITLE;
    },
    shareDescription() {
      return process.env.VUE_APP_SHARE_DESCRIPTION;
    },
    shareQuote() {
      return process.env.VUE_APP_SHARE_QUOTE;
    },
    shareHashtags() {
      return process.env.VUE_APP_SHARE_HASHTAGS;
    },
  },
  methods: {
    async getPaymentLink(paymentLinkId) {
      this.isLoading = true;
      this.$feedback.showLoading();
      try {
        let { data } = await this.$cppClient.post("/payment-link/details", {
          paymentLinkId,
        });
        this.isLoading = false;
        this.$feedback.hideLoading();
        this.link = data;
      } catch ({ response }) {
        // console.log('in catch')
        this.isLoading = false;
        this.$feedback.showFailed(response);
      }
    },
    copyPaymentLink() {
      let textToCopy =
        process.env.VUE_APP_CUSTOMER_PORTAL_URL + this.link.urlPath;
      const copied = navigator.clipboard.writeText(textToCopy);
      if (copied) {
        this.$gtag.event("Payment link copied", {
          event_category:
            this.$route.name === "dashboard"
              ? "Dashboard Page"
              : "Payment Links Page",
        });
        this.$feedback.showShortToast("Link Copied!");
      }
    },
  },
};
</script>

<style scoped lang="scss">
$mobileW: 500px;
$mobileSm: 395px;
$mobileM: 565px;

.card-container {
  margin: 100px auto auto auto;
  background-color: #fff;
  padding: 40px;

  @media (max-width: $mobileM) {
    width: 95% !important;
    padding: 20px !important;
  }
}

.title-text {
  color: #464646;
  font-size: 18px;
  font-weight: 500;

  @media (max-width: $mobileM) {
    font-size: 16px;
  }
}
</style>
